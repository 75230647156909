import Vue from 'vue';
import Vuex from 'vuex';
import user from './user';
import chain from './chain';
import components from './components';

import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    user,
    chain,
    components,
  },
  plugins: [
    createPersistedState({
      paths: ['user'],
    })
  ]
});

export default store;