<template>
  <div>
    <footer class="footer">
      <div class="footer__inner view">
        <div class="footer__inner--info">
          <div class="top-info">
            <div class="logo"></div>
            <div class="sns">
              <v-btn target="_blank" :href="item" v-for="(item, index) in snsArray" :key="index" :class="[index]"></v-btn>
            </div>
          </div>
          <div class="company-info">
            <ul>
              <li>COPYRIGHT © ME2ON Co.,Ltd. All rights Reserved.</li>
              <li>Contact: xheroesnftwar@gmail.com</li>
            </ul>
          </div>
        </div>
        <div class="footer__inner--sns">
          <v-btn target="_blank" :href="item" v-for="(item, index) in snsArray" :key="index" :class="[index]"></v-btn>
        </div>
      </div>
    </footer>

    <div class="fixed-sns">
      <div class="fixed-sns__inner">
        <v-btn target="_blank" :href="item" v-for="(item, index) in snsArray" :key="index" :class="[index]"></v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppFooterView',
  data() {
    return {
      snsArray: {
        Facebook: 'https://www.facebook.com/XHeroesNFTWar',
        Discord: 'https://discord.gg/meverseofficialchannel',
        Twitter: 'https://twitter.com/XHeroesNFTWar',
        Medium: 'https://medium.com/@xheroesnftwar',
        Docs: 'https://meversedex.gitbook.io/x-heroes-nft-war/'
      },
    }
  }
}
</script>

<style>

</style>