import config from '@/config';
import define from '@/define';
import types from './types';
import {
    web3,
} from '@/libs/injectionManager';
import { face } from '@/utils/facewalletLogin';

import { getCookie, setCookie } from '@/utils/cookie';

const {
    CHANGE_CHAIN_ID,
    CHANGE_ALLOW
} = types;

const chainModule =  {
    state: {
        chain: {
            id: '',
            type: '',
            number: undefined,
        },
        isAllowChain: false,

    },
    getters: {
        getChainId: (state) => state.chain.id,
        isAllowChain: (state) => state.isAllowChain,
    },
    mutations: {
        changeChainId(state, { chainId, type }) {
            state.chain.number = config.chainNumber[chainId];
            state.chain.id = chainId;
            state.chain.type = type;
            setCookie('xheroes_chain', JSON.stringify(state.chain), 30*24);
        },
        changeAllow(state, { isAllowChain }) {
            state.isAllowChain = isAllowChain;
        },
    },
    actions: {
        changeChainId(context,  { chainId, type }) {
            let num = chainId-0
            num = "0x" + num.toString(16)
            context.commit(CHANGE_CHAIN_ID, { chainId, type });
            context.commit(CHANGE_ALLOW, { isAllowChain: config.allowChain.includes(chainId)||config.allowChain.includes(num) });
        },
        async checkChain(context) {
            const chain = getCookie('xheroes_chain');
            if (!chain) {
                return;
            }
            try {
                const info = JSON.parse(chain);
                let nwv = null;
                if (info.type == define.FACE_WALLET) {
                    nwv = await face.getChainId()
                } else if (info.type == define.METAMASK) {
                    nwv = await web3.eth.net.getId()
                }
                let num = info.id-0
                num = "0x" + num.toString(16)

                if (nwv-0 == info.id-0) {
                    context.commit(CHANGE_CHAIN_ID, { chainId:nwv-0, type: info.type });
                    context.commit(CHANGE_ALLOW, { isAllowChain: config.allowChain.includes(info.id)||config.allowChain.includes(num) });
                } else {
                    context.commit(CHANGE_CHAIN_ID, { chainId:nwv-0, type: info.type });
                    context.commit(CHANGE_ALLOW, { isAllowChain: config.allowChain.includes(nwv)||config.allowChain.includes(num) });
                }
            } catch (e) {
                console.error(e);
            }
        },
    }
}

export default chainModule;