import types from "@/store/components/types";

const {
  PUSH_COMPONENT,
  OPEN_SELECT_WALLET,
  CLOSE_SELECT_WALLET,
  OPEN_SUBMIT_EMAIL,
  CLOSE_SUBMIT_EMAIL,
  OPEN_REFERRAL_CODE,
  CLOSE_REFERRAL_CODE,
  OPEN_REDEEM,
  CLOSE_REDEEM,
} = types;

const components = {
  state: {
    items: [],
    selectWallet: {
      init: false,
      state: undefined,
    },
    submitEmail: {
      init: false,
      state: undefined,
    },
    referralCode: {
      init: false,
      state: undefined,
      data: {},
    },
    redeem: {
      init: false,
      state: undefined,
    },
  },
  getters: {
    getSelectWalletInit: (state) => state.selectWallet.init,
    getSubmitEmailInit: (state) => state.submitEmail.init,
    getReferralCodeInit: (state) => state.referralCode.init,
    getRedeemInit: (state) => state.redeem.init,
  },
  actions: {
    openSelectWalletModal(context) {
      const isInit = context.getters.getSelectWalletInit;
      if (!isInit) {
        let component = () =>
          import(
            /* webpackChunkName: "SelectWallet" */ "@/components/modal/SelectWallet.vue"
          );
        context.commit(PUSH_COMPONENT, { component, id: "SelectWallet" });
      }
      context.commit(OPEN_SELECT_WALLET);
    },
    closeSelectWalletModal(context) {
      context.commit(CLOSE_SELECT_WALLET);
    },
    openSubmitEmailModal(context) {
      const isInit = context.getters.getSubmitEmailInit;
      if (!isInit) {
        let component = () =>
          import(
            /* webpackChunkName: "SubmitEmail" */ "@/components/modal/SubmitEmail.vue"
          );
        context.commit(PUSH_COMPONENT, { component, id: "SubmitEmail" });
      }
      context.commit(OPEN_SUBMIT_EMAIL);
    },
    closeSubmitEmailModal(context) {
      context.commit(CLOSE_SUBMIT_EMAIL);
    },
    openReferralCodeModal(context, { data }) {
      const isInit = context.getters.getReferralCodeInit;
      if (!isInit) {
        let component = () =>
          import(
            /* webpackChunkName: "ReferralCode" */ "@/components/modal/ReferralCode.vue"
          );
        context.commit(PUSH_COMPONENT, { component, id: "ReferralCode" });
      }
      context.commit(OPEN_REFERRAL_CODE, { data });
    },
    closeReferralCodeModal(context) {
      context.commit(CLOSE_REFERRAL_CODE);
    },
    openRedeemModal(context) {
      const isInit = context.getters.getRedeemInit;
      if (!isInit) {
        let component = () =>
          import(
            /* webpackChunkName: "Redeem" */ "@/components/modal/Redeem.vue"
          );
        context.commit(PUSH_COMPONENT, { component, id: "Redeem" });
      }
      context.commit(OPEN_REDEEM);
    },
    closeRedeemModal(context) {
      context.commit(CLOSE_REDEEM);
    },
  },
  mutations: {
    [PUSH_COMPONENT](state, { component, id }) {
      state.items.push({ component, id });
    },
    [OPEN_SELECT_WALLET](state) {
      state.selectWallet = {
        init: true,
        state: true,
      };
    },
    [CLOSE_SELECT_WALLET](state) {
      state.selectWallet.state = false;
    },
    [OPEN_SUBMIT_EMAIL](state) {
      state.submitEmail = {
        init: true,
        state: true,
      };
    },
    [CLOSE_SUBMIT_EMAIL](state) {
      state.submitEmail.state = false;
    },
    [OPEN_REDEEM](state) {
      state.redeem = {
        init: true,
        state: true,
      };
    },
    [CLOSE_REDEEM](state) {
      state.redeem.state = false;
    },
    [OPEN_REFERRAL_CODE](state, { data }) {
      state.referralCode = {
        init: true,
        state: true,
        data
      };
    },
    [CLOSE_REFERRAL_CODE](state) {
      state.referralCode.state = false;
      state.referralCode.data = {};
    },
  },
};
export default components;
