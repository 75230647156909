
const env = process.env.VUE_APP_ENV;
const isProduction = env === 'production' ? true : false;

import define from '@/define';
import store from '@/store';
import { Face, Network } from '@haechi-labs/face-sdk';
import { ethers } from 'ethers';
// import { addLoginAPI } from '@/api/server/auth/index.js';
// import { getUserInfoAPI } from '@/api/server/player/index.js';


const API_KEY = define.FACE_WALLET_API_KEY

export const face = new Face({
  network: isProduction ? Network.MEVERSE : Network.MEVERSE_TESTNET,
  apiKey: API_KEY
});

export const provider = new ethers.providers.Web3Provider(face.getEthLikeProvider());
export const signer = provider.getSigner();

// export const currentNetwork = async () => {
//   const test = await face.getNetwork();
// }

export const getChainId = async () => {
  await face.getChainId()
  .then(res => {
    let num = res-0
    num = "0x" + num.toString(16)
    store.dispatch('changeChainId', { chainId: num, type: 'faceWallet' });
  })
}

export const isLoggedIn = async () => {
  await face.auth.isLoggedIn();
}

export const getBalance = async () => {
  const userAddress = await signer.getAddress();
  const balance = ethers.utils.formatEther(
    await provider.getBalance(userAddress)
  );
  return balance;
}

let prevAddress;
let interval;

const loginCheck = (address) => {
  const prev = store.getters.userInfo.walletAddress;
  if (address === prev) {
    return;
  }

  if (prevAddress === address) {
    return;
  }

  if (interval) {
    clearTimeout(interval);
    interval = null;
    prevAddress = '';
    return;
  }

  interval = setTimeout(() => {
    login(address);
    prevAddress = '';
    interval = null;
  }, 50);

  prevAddress = address;
  getChainId();
}

const login = async (address) => {
  store.dispatch('login', { userInfo: {
    walletAddress: address,
  }});
  // const balance = await getBalance();
  // addLoginAPI(address, balance)
  // .then(() => {
  //   getUserInfoAPI(address)
  //   .then(res => {
  //     const item = res?.data;
  //     store.dispatch('login', { userInfo: {
  //       walletAddress: address,
  //       playerId: item.player_id,
  //     }});
  //   })
  //   .catch(e => {
  //     console.log('getUserInfo e: ', e);
  //   })
  // })
  // .catch(e => {
  //   console.log('addLogin e: ', e);
  // })
}

// export const connectToFaceWallet = async () => {
//   await face.auth.login()
//   .then(async () => {
//     const address = await signer.getAddress();
//     loginCheck(address)
//   })
//   .catch(e => {
//     console.log('error: ', e)
//   })
// }

export const connectToFaceWallet = async (social) => {
  if (social == 'Google') {
    await face.auth.directSocialLogin('google.com')
  } else if (social == 'Facebook') {
    await face.auth.directSocialLogin('facebook.com')
  } else if (social == 'Apple') {
    await face.auth.directSocialLogin('apple.com')
  }
  try {
    const address = await signer.getAddress();
    loginCheck(address)
  }
  catch(e) {
    console.log('error: ', e)
  }
}

export const disconnectFaceWallet  = async () => {
  await face.auth.logout();
}

export const switchNetwork  = async () => {
  const NETWORK = isProduction ? Network.MEVERSE : Network.MEVERSE_TESTNET;
  await face.switchNetwork(NETWORK);
}

export const connectToFaceWalletPromise = (resolve, reject) => {
  if (provider !== 'undefined' && provider) {
    switchNetwork()
    .then(() => {
      resolve(true);
    })
    .catch((err) => {
      reject(err);
    })
  }
};

  // export const signer = () => {
  //   provider.getSigner();
  // };

  // const signer = provider.getSigner();

  // const userAddress = await signer.getAddress();
  // const balance = ethers.utils.formatEther(
  //     // Get user's MEV balance in wei
  //     await provider.getBalance(userAddress)
  // );
  // console.log(userAddress, balance);

// export const getAccountInfo = async () => {
//   const address = await signer.getAddress();
//   const balance = await signer.getBalance();
//   const user = await face.auth.getCurrentUser();

//   console.group('[Account Information]');
//   console.log('Balance:', balance);
//   console.log('Address:', address);
//   console.log('Current user:', user);
//   console.groupEnd();

//   const result = { address: address, balance: balance, user: user }
//   return result;
// }

// export const setAccount  = async (address, balance, user) => {
//   const result = { address: address, balance: balance, user: user }
//   return result;
// }