export default {
  PUSH_COMPONENT: "PUSH_COMPONENT",
  OPEN_SELECT_WALLET: "OPEN_SELECT_WALLET",
  CLOSE_SELECT_WALLET: "CLOSE_SELECT_WALLET",
  OPEN_SUBMIT_EMAIL: "OPEN_SUBMIT_EMAIL",
  CLOSE_SUBMIT_EMAIL: "CLOSE_SUBMIT_EMAIL",
  OPEN_REFERRAL_CODE: "OPEN_REFERRAL_CODE",
  CLOSE_REFERRAL_CODE: "CLOSE_REFERRAL_CODE",
  OPEN_REDEEM: "OPEN_REDEEM",
  CLOSE_REDEEM: "CLOSE_REDEEM",
};
