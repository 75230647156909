import axios from '@/utils/axios';
import define from '@/define';
const url = define.URL;


/**
 * user ip 조회
 */
export const getUserIpAPI = async () => {
  return new Promise((resolve, reject) => {
    const requestUrl = 'https://api.ipify.org?format=json';
    axios.get(requestUrl)
    .then(res => {
      resolve(res?.data.ip);
    })
    .catch(e => {
      reject(e);
    })
  })
}

/**
 * user ip 조회
 */
export const getCountryCodeAPI = async (user_ip) => {
  return new Promise((resolve, reject) => {
    const requestUrl = `https://pro.ip-api.com/json/${user_ip}?key=jSAXpLr7r3IB4mI`;
    axios.get(requestUrl)
    .then(res => {
      resolve(res?.data.countryCode);
    })
    .catch(e => {
      reject(e);
    })
  })
}

/**
 * 레퍼럴 정보 조회
 * @param {String} wallet_address 지갑 주소
 */
export const getReferralInfoAPI = async (wallet_address) => {
  return new Promise((resolve, reject) => {
    const requestUrl = url + `/xheroes/getReferralInfo/${wallet_address}`;
    axios.get(requestUrl)
    .then(res => {
      resolve(res);
    })
    .catch(e => {
      reject(e);
    })
  })
}


/**
 * 이메일 등록
 */
export const addEmailAPI = async (referral_id, wallet_address, email, user_ip, country_code) => {
  return new Promise((resolve, reject) => {
    const requestUrl = url + `/xheroes/addEmail`;
    const body = {
      referral_id,
      wallet_address,
      email,
      user_ip,
      country_code
    };
    axios.post(requestUrl, body)
    .then(res => {
      resolve(res);
    })
    .catch(e => {
      reject(e);
    })
  })
}

/**
 * 랭킹 조회
 */
export const getRankInfoAPI = async () => {
  return new Promise((resolve, reject) => {
    const requestUrl = url + `/xheroes/getTop5List`;
    axios.get(requestUrl)
    .then(res => {
      resolve(res);
    })
    .catch(e => {
      reject(e);
    })
  })
}