<template>
  <div class="connect-bar">
    <div v-if="walletAddress" class="connect-bar__inner">
      <div v-if="!isAllowChain" class="connect">
        <v-btn @click="changeChain">Connect Wallet</v-btn>
      </div>
      <div v-else class="userIn">
        <!-- <v-btn @click="openUserModal" class="referral-btn">Referral</v-btn> -->
        <div class="drop">
          <v-btn @click="activeDrop" :class="{ 'isDrop': tab }" v-click-outside="closeDrop" class="drop--btn">
            <img src="@/assets/media/Icon/FaceWallet_bg.svg" />
            <p>{{ concatAddress(walletAddress, 4, 4) }}</p>
          </v-btn>
          <v-expand-transition>
            <div v-if="tab" class="drop--inner">
              <v-btn @click="openFaceWalletHome">Wallet</v-btn>
              <v-btn @click="logout">Disconnect</v-btn>
            </div>
          </v-expand-transition>
        </div>
      </div>
    </div>
    <div v-else class="connect">
      <v-btn @click="openSelectWallet">Connect Wallet</v-btn>
    </div>
  </div>
</template>

<script>
import { addChainPromise } from '@/utils/chainToConnect';
import { Network } from '@haechi-labs/face-sdk';
import define from '@/define';
import config from '@/config';
import { face, disconnectFaceWallet } from '@/utils/facewalletLogin';
export default {
  name: "ConnectWallet",
  data() {
    return {
      isFaceChainId: undefined,
      tab: false,
    }
  },
  computed: {
    walletAddress() {
      return this.$store.state.user.userInfo.walletAddress;
    },
    chainId() {
      return this.$store.state.chain.chain.id;
    },
    isAllowChain() {
      const walletType = this.$store.state.chain.chain.type ?? define.FACE_WALLET;
      if (walletType == define.FACE_WALLET && this.isFaceChainId) {
        if (config.allowChain.includes(this.isFaceChainId)) {
          return true;
        }
      }
      return this.$store.getters.isAllowChain;
    },
    userRefInfo() {
      return this.$store.state.user.userRefInfo;
    },
  },
  async mounted() {
    await this.faceChainId();
  },
  methods: {
    changeChain() {
      addChainPromise(define.FACE_WALLET, define.MEVERSE_NUMBER)
      .then(async () => {
        this.$store.dispatch('logout')
        this.$store.dispatch('openSelectWalletModal');
      })
      .catch((e) => {
        console.log(e);
      })
    },
    openUserModal() {
      const data = this.userRefInfo;
      if (data) {
        this.$store.dispatch('openReferralCodeModal', { data });
      } else {
        this.$store.dispatch('openSubmitEmailModal');
      }
    },
    activeDrop() {
      this.tab = !this.tab;
    },
    closeDrop() {
      this.tab = false;
    },
    openSelectWallet() {
      this.$store.dispatch('openSelectWalletModal');
    },
    async faceChainId() {
      await face.getChainId()
      .then(res => {
        let num = res-0
        num = "0x" + num.toString(16)
        this.isFaceChainId = num;
      })
    },
    logout() {
      const walletType = this.$store.state.chain.chain.type ?? define.FACE_WALLET;
      if (walletType == define.FACE_WALLET) {
        disconnectFaceWallet()
      }
      this.$store.dispatch('logout')
    },
    async openFaceWalletHome() {
      const env = process.env.VUE_APP_ENV;
      const isProduction = env === 'production' ? true : false;

      try {
        await face.wallet.home({
          networks: [
            isProduction ? Network.MEVERSE : Network.MEVERSE_TESTNET // Selected networks
          ]
        });
      } catch (e) {
        this.changeChain()
        // if(e.isFaceError) {
        //   switch (e.code) {
        //     case FaceErrorCode.NO_USER_DATA:
        //       this.changeChain()
        //       break;
        //   }
        // }
      }
    }
  }
}
</script>

<style>

</style>