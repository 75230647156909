<template>
  <header class="header">
    <div class="header__screen">
      <div class="header__screen--info">
        <div @click="goPage('/')" class="logo"></div>
        <ul class="gnb">
          <li class="gnb__title">
            <v-btn @click="goPage('/event')" class="gnb__title--link">Event</v-btn>
          </li>
          <li class="gnb__title">
            <!-- <v-btn @click="openSoon" class="gnb__title--link">Redeem</v-btn> -->
            <v-btn @click="openRedeemModal" class="gnb__title--link">Redeem</v-btn>
          </li>
          <li class="gnb__title">
            <v-btn href="https://www.meversedex.io/swap?f=MEV&t=MPL" target="_blank" class="gnb__title--link">MPL Exchange</v-btn>
          </li>
          <li class="gnb__title">
            <v-btn @click="goSection" class="gnb__title--link">News</v-btn>
          </li>
          <li class="gnb__title">
            <v-btn href="https://meversedex.gitbook.io/x-heroes-nft-war/" target="_blank" class="gnb__title--link">Docs</v-btn>
          </li>
        </ul>
      </div>
      <div class="header__screen--user">
        <div class="user-controller">
          <connect-wallet />
        </div>
        <img @click="openMenu" class="menu-btn" src="@/assets/media/Icon/Menu.svg" alt="Menu 메뉴" />
      </div>
    </div>
    <div :class="{ 'isActive': menu }" @click="closeMenu" class="header__mask"></div>
    <div class="header__mobile" :class="{ 'toggleMenu': menu }">
      <div class="header__mobile--inner">
        <v-btn class="close-btn" @click="closeMenu"></v-btn>
        <ul class="m-gnb">
          <li class="m-gnb__title">
            <v-btn @click="goPage('/event')" class="m-gnb__title--link">Event</v-btn>
          </li>
          <li class="m-gnb__title">
            <!-- <v-btn @click="openSoon" class="m-gnb__title--link">Redeem</v-btn> -->
            <v-btn @click="openRedeemModal" class="m-gnb__title--link">Redeem</v-btn>
          </li>
          <li class="m-gnb__title">
            <v-btn href="https://www.meversedex.io/swap?f=MEV&t=MPL" target="_blank" @click="closeMenu" class="m-gnb__title--link">MPL Exchange</v-btn>
          </li>
          <li class="m-gnb__title">
            <v-btn @click="goSection" class="m-gnb__title--link">News</v-btn>
          </li>
          <li class="m-gnb__title">
            <v-btn href="https://meversedex.gitbook.io/x-heroes-nft-war/" target="_blank" @click="closeMenu" class="m-gnb__title--link">Docs</v-btn>
          </li>
        </ul>
        <div class="m-footer">
          <connect-wallet-mobile />
        </div>
      </div>
    </div>
    <v-snackbar
      class="msg-bar"
      color="#FFFFFF"
      v-model="soon"
      :timeout="3000"
    >
    <div class="msg-bar__inner">
      <p>Coming soon</p>
    </div>
    <v-btn @click="closeSoon" class="close-btn"></v-btn>
    </v-snackbar>
  </header>
</template>

<script>
import config from '@/config';
import ConnectWallet from "../common/ConnectWallet";
import ConnectWalletMobile from "../common/ConnectWalletMobile";
export default {
  name: 'AppBarView',
  data() {
    return {
      menu: false,
      soon: false
    }
  },
  computed: {
    walletAddress() {
      return this.$store.state.user.userInfo.walletAddress;
    },
    isAllowChain() {
      if (window.ethereum && window.ethereum?.chainId) {
        const chainId = window.ethereum.chainId;
        if (config.allowChain.includes(chainId)) {
          return true;
        }
      }
      return this.$store.getters.isAllowChain;
    },
    uuid() {
      return this.$route.params.uuid
    }
  },
  components: {
    ConnectWallet,
    ConnectWalletMobile
  },
  mounted() {
  },
  methods: {
    openSoon() {
      this.soon = true;
      if (this.menu) return this.closeMenu()
    },
    closeSoon() {
      this.soon = false;
    },
    openRedeemModal() {
      this.$store.dispatch('openRedeemModal');
      if (this.menu) return this.closeMenu()
    },
    openMenu() {
      this.menu = true;
      document.querySelector('html').style.overflow = 'hidden';
    },
    closeMenu() {
      this.menu = false;
      document.querySelector('html').style.overflow = 'visible';
    },
    goPage(path) {
      const referralId = this.uuid;
      referralId ? this.$router.push(`/ref/${referralId}${path}`).catch(()=>{}) : this.$router.push(`${path}`).catch(()=>{});
      if (this.menu) return this.closeMenu()
    },
    goSection() {
      const referralId = this.uuid;
      referralId ?  this.$router.push({ path: `/ref/${referralId}`, hash: 'news' }).catch(() => {}) : this.$router.push(`/#news`).catch(()=>{});
      if (this.menu) return this.closeMenu()
    }
  }
}
</script>

<style>

</style>