import types from './types';
import { getloggedInData } from '@/utils/cookie';
import { getReferralInfoAPI } from '@/api/server/xheroes/index.js';
import { signer } from '@/utils/facewalletLogin';

const {
  SET_USER_INFO,
  REMOVE_USER_INFO,
  CHECK_USER_INFO,
  LOGIN,
  LOGOUT,
} = types;

const tokenSymbol = 'MEV'

const userModule = {
  state: {
    userInfo: {
      walletAddress: '',
    },
    userRefInfo: '',
  },
  getters: {
      userInfo: state => state.userInfo,
      userRefInfo: state => state.userRefInfo,
  },
  mutations: {
    [SET_USER_INFO](state, userInfo) {
      state.userInfo = userInfo;
      const now = new Date()
      const item = {
        value: userInfo,
        expiry: now.getTime() + 60*60*1000,
      }
      localStorage.setItem('expiryXheroes', JSON.stringify(item));
    },
    [REMOVE_USER_INFO](state) {
      state.userInfo =  {
        walletAddress: '',
      },
      state.userRefInfo = '';
      localStorage.removeItem('expiryXheroes');
    },
    userRefInfo(state, ref) {
      state.userRefInfo = ref;
    },
  },
  actions: {
    [LOGIN]({ commit }, { userInfo }) {
      commit(SET_USER_INFO, userInfo);
    },
    [LOGOUT]({ commit }) {
      commit(REMOVE_USER_INFO)
    },
    async [CHECK_USER_INFO]({ commit }) {
      const userInfo = getloggedInData('expiryXheroes');
      const address = await signer.getAddress();
      if (userInfo) {
        if (address == userInfo.walletAddress) {
          commit(SET_USER_INFO, userInfo);
        } else {
          commit(REMOVE_USER_INFO)
        }
      } else {
        commit(REMOVE_USER_INFO)
      }
    },
    async getReferralInfo({ commit }, wallet_address) {
      if (!wallet_address) return;
      await getReferralInfoAPI(wallet_address)
      .then(res => {
        const ref = res?.data[0];
        commit('userRefInfo', ref);
      })
      .catch(e => {
        console.log('e: ', e);
      })
    },
  },
}

export default userModule;