<template>
  <div class="event">
    <div class="event__banner">
      <div class="event__banner--inner view">
        <div class="information">
          <div class="information__title">
            <div class="information__title--sub">
              <p>X Heroes: NFT War Season 4 Event</p>
            </div>
            <p class="information__title--main">Join us and get Free NFT!</p>
          </div>
          <div class="information__desc">
            <p>Championship Season 4 in X Heroes: NFT War is coming to you! Meet new special NFTs and enjoy a sense of adventure in Chapter 10. Have a blast with these new heroes and grab tons of rewards in X Heroes: NFT War!! Do not miss this great opportunity since it will be available on a first come, first served basis. Enjoy the gameplay as you receive new special NFT Heroes in this season!</p>
          </div>
        </div>
        <div class="section-image">
          <img class="default" src="@/assets/media/Content/items/image07.png" alt="X Heroes Item" />
          <img class="mobile" src="@/assets/media/Content/items/image07-02.png" alt="X Heroes Item" />
        </div>
      </div>
    </div>

    <div class="event__inner view">
      <div class="content-wrap">
        <div class="content">
          <div class="content__item">
            <div class="content__item--title">
              <p>[📢In-game Event]</p>
            </div>
            <div class="content__item--info">
              <p>Event for newbies!</p>
            </div>
            <ul class="content__item--list m-24">
              <li class="title-info">⭐For those who start the gameplay for the first time during the event period.⭐</li>
              <li>⏰ Event period: February 15, 2024 — March 21, 2024</li>
            </ul>
            <ul class="content__item--list m-24">
              <li class="title-info">🏆 Reward:</li>
              <li>&nbsp;&nbsp;&nbsp;&nbsp;✅ 2,000 Blue Diamond</li>
              <li>&nbsp;&nbsp;&nbsp;&nbsp;✅ 4-star SS Class Hero</li>
              <li>&nbsp;&nbsp;&nbsp;&nbsp;✅ 100k Gold</li>
            </ul>
          </div>

          <div class="content__item">
            <div class="content__item--title">
              <p>2x Gold and 2x EXP Event!</p>
            </div>
            <ul class="content__item--list m-24">
              <li class="title-info">⭐Gain 2x gold and 2x EXP and during the event period.⭐</li>
              <li>⏰ Event period: February 15, 2024 — March 21, 2024</li>
            </ul>
            <ul class="content__item--list m-24">
              <li class="title-info">🎁New special NFT raffle event🎁</li>
              <li>1. First 500 users who clear Chapter 10 (1 NFT for each winner.)</li>
              <li>*Any level of difficulty*</li>
              <li>⏰ Event period: February 15, 2024 — March 21, 2024</li>
              <li>🏆 Total Reward: 500 NFTs</li>
            </ul>
            <ul class="content__item--list m-24">
              <li>2. First 50 users who complete all NFT missions daily. (50 NFTs a day, 1 NFT for each winner.)</li>
              <li>⭐For those who clear all NFT missions daily during the event period. The reward (350 NFTs) will be provided to all daily winners once the event ends.⭐</li>
              <li>⏰ Event period: February 15, 2024 — February 21, 2024 (7 days🚩)</li>
              <li>🏆 Total Reward: 350 NFTs</li>
            </ul>
          </div>

          <div class="content__item">
            <div class="content__item--title">
              <p>[📢Community Event]</p>
            </div>
            <ul class="content__item--list m-24">
              <li class="title-info">⭐️New special NFT raffle event⭐️</li>
              <li>Those who complete our quests on Zealy, web3 platform, will get Season 4 NFT!</li>
            </ul>
            <ul class="content__item--list m-24">
              <li class="title-info">🏆 Reward: 1 NFT for each winner</li>
              <li>⏰ Event period: February 1, 2024 — February 11, 2024</li>
            </ul>
            <ul class="content__item--list">
              <li>📋 Quests: All you have to do is</li>
              <li>&nbsp;&nbsp;&nbsp;&nbsp;· Follow X Heroes: NFT War’s official Twitter</li>
              <li>&nbsp;&nbsp;&nbsp;&nbsp;· Follow MEVerse’s official Twitter</li>
              <li>&nbsp;&nbsp;&nbsp;&nbsp;· Like, reply, retweet Championship Season 4 Tweet!</li>
              <li>&nbsp;&nbsp;&nbsp;&nbsp;· Join MEVerse Discord Channel and Find 🎮MPL Channel!</li>
              <li>&nbsp;&nbsp;&nbsp;&nbsp;· Visit X Heroes: NFT War Official Website</li>
            </ul>
          </div>
        </div>
        <div class="actions-wrap">
          <!-- <v-btn @click="openSoon" class="blue">Event Submit</v-btn> -->
          <v-btn href="https://zealy.io/cw/xheroesnftwar/questboard/4b9eecbb-222c-476b-8cb3-88253383608d" target="_blank" class="blue">Community Event Participate</v-btn>
        </div>
      </div>
      <!-- <div class="tab">
        <v-btn @click="changeTab(1)" :class="{ 'isActive': state == 1 }">Event 1</v-btn>
        <v-btn @click="changeTab(2)" :class="{ 'isActive': state == 2 }">Event 2</v-btn>
      </div>
      <div v-if="state == 1" class="content-wrap">
        <div class="content">
          <div class="content__item">
            <div class="content__item--title">
              <p>Rewards #1 | Pre-registration Reward to Everyone!</p>
            </div>
            <div class="content__item--info">
              <p>Various rewards will be given out to EVERYONE who participate in pre-registration.</p>
              <p>Reward Coupon Code will be sent out to your email that you used to submit for pre-registration.</p>
            </div>
            <ul class="content__item--list">
              <li>Reward List</li>
              <li>&nbsp;&nbsp;· 2,000 Blue Diamond</li>
              <li>&nbsp;&nbsp;· 4-star SS Class Hero</li>
              <li>&nbsp;&nbsp;· 100K Gold </li>
            </ul>
          </div>

          <div class="content__item">
            <div class="content__item--title">
              <p>Rewards #2 | The Bigger The Better!</p>
            </div>
            <div class="content__item--info">
              <p>We would like to reward everyone who participate in pre-registration.</p>
              <p>As we bring more people in, the more reward you will get! Reward will be given out in in-game mailbox after the launch!</p>
            </div>
            <ul class="content__item--list">
              <li>Reward List</li>
              <li>&nbsp;&nbsp;· 5,000+ : 500 Blue Diamond + 3-star A Class Hero</li>
              <li>&nbsp;&nbsp;· 10,000+ : 1,000 Blue Diamond + 3-star S Class Hero</li>
              <li>&nbsp;&nbsp;· 30,000+ : 1,000 Blue Diamond + 4-star S Class Hero</li>
              <li>&nbsp;&nbsp;· 50,000+ : 1,500 Blue Diamond + 3-star SS Class Hero</li>
              <li>&nbsp;&nbsp;· 100,000+ : 2,000 Blue Diamond + 4-star SS Class Hero</li>
            </ul>
          </div>

          <div class="content__item">
            <div class="content__item--title">
              <p>Rewards #3 | Let your friends know about us!</p>
              <span>Referral Code Required</span>
            </div>
            <div class="content__item--info">
              <p>It’s time to let your friends know about us, X Heroes: NFT War!</p>
              <p>To create your own Referral Code, log-in with Face Wallet using your social media account. Let’s see who has the most friends out of all!</p>
            </div>
            <ul class="content__item--list">
              <li>Reward List</li>
              <li>&nbsp;&nbsp;· 1st Place : 70,000 MPL Token + D’Cent Card Wallet</li>
              <li>&nbsp;&nbsp;· 2nd Place : 35,000 MPL Token + D’Cent Card Wallet</li>
              <li>&nbsp;&nbsp;· 3rd Place : 30,000 MPL Token + D’Cent Card Wallet</li>
              <li>&nbsp;&nbsp;· 4th Place : 25,000 MPL Token + D’Cent Card Wallet</li>
              <li>&nbsp;&nbsp;· 5th Place : 20,000 MPL Token + D’Cent Card Wallet</li>
            </ul>
          </div>

          <div class="content__rank">
            <p class="point" @click="openRank" v-click-outside="closeRank">View referral rank</p>
            <div v-if="viewRank" class="rank-wrap">
              <ul class="rank-wrap__header">
                <li class="center">rank</li>
                <li>Email</li>
                <li class="center">invites</li>
              </ul>
              <ul class="rank-wrap__body" v-for="(item, index) in rank" :key="index">
                <li class="center">{{ item.referral_rank }}</li>
                <li>{{ item.email }}</li>
                <li class="center">{{ item.referral_count }}</li>
              </ul>
            </div>
          </div>

        </div>
        <div class="actions-wrap">
          <v-btn @click="openUserModal" v-if="walletAddress" class="blue">Submit your Email</v-btn>
          <div v-else class="actions-wrap__inner">
            <v-btn @click="openSelectWallet" class="blue">Join with Face Wallet <div class="ea">(I want my referral code!)</div></v-btn>
            <v-btn @click="openSubmitEmailModal" class="black">Join with E-mail <div class="ea">(I don’t need my referral code!)</div></v-btn>
          </div>
        </div>
      </div>
      <div v-else-if="state == 2" class="content-wrap">
        <div class="content">
          <div class="content__item">
            <div class="content__item--title">
              <p>Pre-registration Web 3.0 Quest!</p>
            </div>
            <div class="content__item--info">
              <p>Complete Web 3.0 quests to receive X Heroes: NFT War special NFT!</p>
            </div>
            <ul class="content__item--list">
              <li>Quests</li>
              <li>&nbsp;&nbsp;· Follow &#38; Like official Facebook page</li>
              <li>&nbsp;&nbsp;· Follow official Twitter</li>
              <li>&nbsp;&nbsp;· Join official Discord</li>
              <li>&nbsp;&nbsp;· Share Pre-registration link in Social Media</li>
            </ul>
            <ul class="content__item--list">
              <li>Reward List</li>
              <li>&nbsp;&nbsp;· 1 Special NFT Mystery Card</li>
              <li>&nbsp;&nbsp;· Total of 1,000 Mystery Cards will be given out as reward</li>
              <li>&nbsp;&nbsp;· If participants exceeds 1,000 participants, team will use randomizer to select 1,000 winners</li>
            </ul>
          </div>
        </div>
        <div class="actions-wrap">
          <v-btn href="https://zealy.io/c/meverse/questboard" target="_blank" class="blue link">Join Web 3.0 Event</v-btn>
        </div>
      </div> -->
    </div>
    <v-snackbar
      class="msg-bar"
      color="#FFFFFF"
      v-model="soon"
      :timeout="3000"
    >
    <div class="msg-bar__inner">
      <p>Coming soon</p>
    </div>
    <v-btn @click="closeSoon" class="close-btn"></v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { getRankInfoAPI } from '@/api/server/xheroes/index.js';
export default {
  name: 'EventView',
  data() {
    return {
      tab: 1,
      state: 1,
      rank: [],
      viewRank: false,
      soon: false
    }
  },
  computed: {
    walletAddress() {
      return this.$store.state.user.userInfo.walletAddress;
    },
    userRefInfo() {
      return this.$store.state.user.userRefInfo;
    },
  },
  mounted() {
    this.getRankInfo()
  },
  methods: {
    openSoon() {
      this.soon = true;
      if (this.menu) return this.closeMenu()
    },
    closeSoon() {
      this.soon = false;
    },
    changeTab(tab) {
      this.state = tab;
    },
    openUserModal() {
      const data = this.userRefInfo;
      if (data) {
        this.$store.dispatch('openReferralCodeModal', { data });
      } else {
        this.$store.dispatch('openSubmitEmailModal');
      }
    },
    openSubmitEmailModal() {
      this.$store.dispatch('openSubmitEmailModal');
    },
    openSelectWallet() {
      this.$store.dispatch('openSelectWalletModal');
    },
    getRankInfo() {
      getRankInfoAPI()
      .then(res => {
        this.rank = res?.data;
      })
      .catch(e => {
        console.log('getRankInfoAPI e: ', e);
      })
    },
    openRank() {
      this.viewRank = true;
    },
    closeRank() {
      this.viewRank = false;
    }
  }
}
</script>

<style>

</style>