<template>
  <div class="home">
    <div class="home__banner">
      <div class="home__banner--inner view">
        <div class="home-title">
          <div class="home-title__img">
            <img src="@/assets/media/Content/banner/Home_title.png" alt="Home title">
          </div>
          <p>WORLD BEST WEB3 TURN-BASED STRATEGY RPG</p>
        </div>
        <v-btn @click="openDownloadModal" class="play-btn">PLAY NOW</v-btn>
        <!-- <v-btn @click="goPage('/event')" class="play-btn">Pre-register</v-btn> -->
      </div>
    </div>
    <div class="home__inner">
      <div class="section00 view">
        <div class="section00__inner">
          <div class="section00-image01">
            <img src="@/assets/media/Content/items/image00.png" alt="X Heroes Item" />
          </div>
          <img class="MPL-symbol" src="@/assets/media/Content/items/image00_01.png" alt="MPL Symbol Item" />
          <div class="content-title">
            <p>WHAT IS MPL?</p>
            <span>MEVerse Play (MPL) is a utility token for ME2ON's onboarding games which can be swapped to USDC at meversedex.io</span>
          </div>
        </div>
      </div>
      <div class="section01 view">
        <div class="section01__inner">
          <div class="content top">
            <div class="content__inner">
              <div class="content-title">
                <p>COLLECT AND OWN NFT</p>
                <span>Start your adventure with free heroes.<br />You will get various heroes by playing the game and can mint your heroes to trade at MEVerse Market place. There are over hundred heroes in the game, and it will be updated regularly.<br /> When you mint NFT, its ownership is yours. You can use it or even trade it.</span>
              </div>
            </div>
            <div class="content__view">
              <div class="content__view--video" @click="openVideoModal">
                <v-btn></v-btn>
              </div>
              <div class="section01-image01">
                <img src="@/assets/media/Content/items/image01.png" alt="X Heroes Item" />
              </div>
            </div>
          </div>
          <div class="content bottom">
            <div class="content__view">
              <div class="section01-image02">
                <img src="@/assets/media/Content/items/image02.png" alt="X Heroes Item" />
              </div>
            </div>
            <div class="content-btn">
              <div class="content-title">
                <p>POWER UP YOUR NFT</p>
                <span>Heroes are gaining EXP during the battle. You can enhance, promote, and awaken your heroes to make them stronger. More power can bring you more comfortable gameplay and more rewards.</span>
              </div>
              <div class="content-btn__inner">
                <v-btn href="https://www.meversedex.io/marketplace" target="_blank" class="active-btn">Explore</v-btn>
                <v-btn href="https://meversedex.gitbook.io/x-heroes-nft-war/" target="_blank" class="line-btn">Docs</v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section02">
        <div class="section02__inner view">
          <div class="content top">
            <div class="content__inner">
              <div class="content-title">
                <p>COMPETITION AND COOPERATION</p>
                <ul>
                  <li>You must fight with your enemy at ARENA and CHAMPIONSHIP at Colosseum.<br />
                    Of course, you can skip the competition, but Big rewards will be given in the competition.<br />
                    There’s not only competition but also cooperation with other players.</li>
                  <li>You can make friends or join the Guild to get support from them.<br />
                    Based on the guild level, you can get more EXP and GOLD.</li>
                </ul>
              </div>
            </div>
            <div class="content__view">
              <div class="section02-image01"></div>
              <div class="section02-image02">
                <img src="@/assets/media/Content/items/image04.png" alt="X Heroes Item" />
              </div>
            </div>
          </div>

          <div class="content bottom">
            <div class="content__view">
              <div class="section02-image03">
                <img src="@/assets/media/Content/items/image05.png" alt="X Heroes Item" />
              </div>
            </div>
            <div class="content__inner">
              <div class="content-title">
                <p>PLAY AND EARN</p>
                <span>By completing daily quest, you can get MPL, and change it to another crypto. When you own special NFT, you can play seasonal quests and earn more MPL. Also, you can mint your own heroes into NFT, and sell it at the marketplace.</span>
              </div>
              <div class="content-title">
                <p>MAKE YOUR OWN STRATEGY</p>
                <span>Most important part of the game is strategy. There are many ways to make your own strategy. You must build your own formation for the battle. Moreover, you must set proper skill for victory. The game provides comfortable automatic systems but sometimes you must initiate each character's skill because it is more efficient.</span>
              </div>
              <v-btn href="https://discord.gg/meverseofficialchannel" target="_blank">Join Community</v-btn>
            </div>
          </div>
        </div>
      </div>


      <div class="section03">
        <div class="section03__inner view">
          <div class="content-title">
            <p>HERO STONE &#38; TOKEN</p>
            <span>Hero Stone(HRS) is a in-game currency that can be obtained by game play.<br class="break" />
              Hero Stone(HRS) and MEVerse Play(MPL) can be exchanged.<br />
              <i>*initial exchange ratio is 1:1, but can be changed upon the reserves.</i><br class="break" />
              MEVerse Play(MPL) can be swapped with other tokens on MEVerse DEX.</span>
          </div>
          <div class="token-symbol">
            <div class="token-symbol__item">
              <img src="@/assets/media/Symbol/HRS.svg" alt="HRS symbol">
              <span>HRS</span>
            </div>
            <div class="token-symbol__item">
              <div class="items">
                <img src="@/assets/media/Symbol/MPL.svg" alt="MPL symbol">
              </div>
              <span>MPL</span>
            </div>
            <div class="token-symbol__item">
              <img src="@/assets/media/Symbol/USDC.svg" alt="USDC symbol">
              <span>USDC</span>
            </div>
          </div>
        </div>
      </div>

      <div id="news" class="section04">
        <div class="section04__inner view">
          <div class="content-title">
            <p>LATEST NEWS</p>
          </div>
          <div class="medium-grid">
            <a target="_blank" :href="item.link" class="medium-grid__item" v-for="(item, index) in mediumList" :key="index">
              <v-img v-if="item.image != null" :src="item.image" class="thumbnail"></v-img>
              <v-img v-else class="thumbnail" :src="require(`@/assets/media/Content/banner/main.png`)"></v-img>
              <div class="item-info">
                <p class="item-info__title" v-html="item.title"></p>
                <p class="item-info__date">{{ item.date }}</p>
              </div>
            </a>
          </div>
        </div>
      </div>

    </div>

    <div v-show="modal_video" class="video-modal">
      <div class="video-modal__inner">
        <div class="video-modal__inner--video">
          <v-btn class="close-btn" @click="closeVideoModal"></v-btn>
          <iframe src="https://www.youtube.com/embed/iNAKfVa7Se0"
            title="YouTube X Heroes video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
        </div>
      </div>
    </div>

    <v-dialog v-model="modal_notice" content-class="large-dialog" style="max-width: rem(541) !important;">
      <div class="Modal SelectWallet">
        <div class="Modal__header">
          <div class="Modal__header--title">
            <p class="main" style="width:100%; text-align: center;;">Service Termination Notice</p>
          </div>
          <v-btn class="Modal__header--close" @click="closeNoticeModal"></v-btn>
        </div>
        <div class="Modal__body">
          <div style="color:#000000; margin: 0 0 30px; text-align: center;">
            <div style="margin: 20px 0;">
              <p style="font-weight: 600">[Service Termination Date and Time]</p>
              December 26, 2024 (Thursday) 6:00 UTC
            </div>
            <div style="margin: 20px 0;">
              <p style="font-weight: 600">[Termination of In-game Shop (including Stone Store)]</p>
              September 19, 2024 (Thursday) 6:00 UTC
            </div>
            <div>
              Thank you for your support and we hope to provide you with 
              more exciting games in the future
            </div>
          </div>
          <div class="select-list">
            <div @click="goDownload(`https://medium.com/@xheroesnftwar/x-heroes-nft-war-service-termination-notice-a78258a366ac`)"
                 class="select-list__col" style="grid-template-columns: none;">
              <div class="select-list__col--text" style="display: block;margin-left: 0;">
                <p>More Details</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-dialog>

    <v-dialog v-model="modal_download" content-class="small-dialog">
      <div class="Modal SelectWallet">
        <div class="Modal__header">
          <div class="Modal__header--title">
            <p class="main">Download</p>
          </div>
          <v-btn class="Modal__header--close" @click="closeDownloadModal"></v-btn>
        </div>
        <div class="Modal__body">
          <div class="select-list">
            <div :class="{ 'notPointer': !item.link }" @click="goDownload(item.link)" v-for="(item, index) in downloadList" :key="index" class="select-list__col">
              <img :src="require(`@/assets/media/Icon/Download_${index}.svg`)" :alt="`${item.name} icon`" />
              <div class="select-list__col--text">
                <p>{{ item.name }}</p>
                <span v-if="!item.link" class="soon">Coming Soon</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    name: 'HomeView',
    data() {
      return {
        mediumList: [],
        modal_notice: true,
        modal_video: false,
        modal_download: false,
        downloadList: {
          // GOOGLE: {
          //   name: 'Google Play',
          //   link: 'https://play.google.com/store/apps/details?id=com.me2on.xheroesa'
          //   // link: ''
          // },
          APPLE: {
            name: 'App Store',
            link: 'https://apps.apple.com/app/apple-store/id6445909469'
          },
          GOOGLE: {
            name: 'Download APK',
            link: 'https://cdndown.fhcasino.com/pocketbattles/apk/xheroesnftwar.apk'
            // link: ''
          },
        }
      }
    },
    computed: {
      uuid() {
        return this.$route.params.uuid
      }
    },
    mounted() {
      this.getMedium()
    },
    methods: {
      goDownload(link) {
        if (!link) return;
        window.open(`${link}`);
      },
      async getMedium() {
        let datas = [];

        const name = "xheroesnftwar";
        const RSSUrl = `https://medium.com/feed/@${name}`;
        const RSSConverter = `https://api.rss2json.com/v1/api.json?rss_url=${RSSUrl}`;

        const response = await fetch(RSSConverter);
        const data = await response.json();

        for(let item of data.items) {
          let image = item.thumbnail;
          if (!/\.(jpg|jpeg|png)$/i.test(image)) {
            image = null;
          }
          datas.push({
            "title": item.title,
            "link": item.link,
            "image": image,
            "date": item.pubDate })
        }

        this.mediumList = datas.slice(0, 3);
      },
      goPage(path) {
        const referralId = this.uuid;
        referralId ? this.$router.push(`/ref/${referralId}${path}`).catch(()=>{}) : this.$router.push(`${path}`).catch(()=>{});
        if (this.menu) return this.closeMenu()
      },
      openNoticeModal() {
        this.modal_notice = true;
      },
      closeNoticeModal() {
        this.modal_notice = false;
      },
      openVideoModal() {
        this.modal_video = true;
      },
      closeVideoModal() {
        this.modal_video = false;
      },
      openDownloadModal() {
        this.modal_download = true;
      },
      closeDownloadModal() {
        this.modal_download = false;
      },
    }
  }
</script>
