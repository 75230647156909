const env = process.env.VUE_APP_ENV;
const isProduction = env === 'production' ? true : false;

export default {
    FACE_WALLET_API_KEY: isProduction ? 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDiiLnJ2pN7wZ2YmmF3wFgmXPwFVl1zY6dXVmbsP8V9O3TUyq5yeGQ_Cm45Muxd4C1VkI8SL6vw5Yg_Wt6k1kBU2OYoTgHQENftiEr0l6v2fURRN6-eta0yeamppEc-JRzJ2GLouilCixinARpnpKl4JX80LjlD_y86OrZ9A6uAAQIDAQAB' : 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC_jR6y4f98bNxDgwzooLU4Jy2XjxqffIXDp0InJDNF6CbRRnAD-z5n-mIVaBc7_M3OVQRP8kSvKX9m5DGL_kxy61cny7O9RoEI42PTn7GloHFsz7C6xpXNf1Nu_z4ZErzpZkgOylnj9s8hSdi8E_Gq0TMBuM1t0Kml3H-EoD-fBwIDAQAB',
    URL: isProduction ? 'https://api.meversegame.io' : 'https://testapi.meversegame.io',
    // DEX_API_URL: isProduction ? 'https://api.meversedex.io' : 'https://api-test.meversedex.io',
    DECIMAL18: 1e18,
    FACE_WALLET: 'faceWallet',
    MEVERSE_DAY_PER_BLOCK: 172800,
    MEVERSE_NUMBER: 0,
    CHAIN_NAME: {
        MEVERSE: 'meverse',
    },
    TOKEN: {
        MEV: isProduction ? '0xef3432f1d54ec559613f44879fef8a7866da3e07' : '0xa1f093a1d8d4ed5a7cc8fe29586266c5609a23e8',
    },
    SYMBOL: {
        // MEV: require(`../assets/media/Symbol/MEV_bg_black.svg`),
    },
}