import config from '@/config';
import define from '@/define';
import { connectToFaceWalletPromise } from '@/utils/facewalletLogin';
export const addChain = async (chainType) => {
  if (chainType === null || chainType === undefined) {
    return;
  }

  const {
    chainName,
    rpcUrls,
    nativeCurrency,
    blockExplorerUrls,
    chainId,
  } = config.chain[chainType];

  try {
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId }],
    });
  } catch (error) {
    if (error.code === 4902) {
      window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId,
            rpcUrls: [rpcUrls],
            chainName,
            nativeCurrency,
            blockExplorerUrls: [blockExplorerUrls],
          },
        ],
      });
    }
  }
};


export const addChainPromise = (walletType, chainType) => {
  return new Promise((resolve, reject) => {
    if (chainType === null || chainType === undefined || walletType === undefined) {
      reject();
      return;
    }
    connectToFaceWalletPromise(resolve, reject)
  })
}

const connectEtherem  = (chainType, resolve, reject) => {
  const {
    chainName,
    rpcUrls,
    nativeCurrency,
    blockExplorerUrls,
    chainId,
  } = config.chain[chainType];
  let id = chainId;
  window.ethereum.request({ method: 'wallet_switchEthereumChain', params: [{ chainId: id }]})
    .then(() => {
      resolve(true);
    }).catch((err) => {
      if (err.code === 4902 || err.code === -32603) {
        window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [{
            chainId,
            rpcUrls: [rpcUrls],
            chainName,
            nativeCurrency,
            blockExplorerUrls: [blockExplorerUrls],
          }],
        })
        .then(() => {
          resolve(false);
        }).catch((e) => {
          reject(e);
        });
      } else {
        reject(err);
      }
    }
  );
}