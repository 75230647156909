export default {
    chainName: 'Meverse Testnet',
    value: 'MEVERSE',
    text: '미버스 테스트 네트워크',
    chainId: '0x1297',
    rpcUrls: 'http://158.247.201.21:8541',
    scanName: 'Meverse Scan',
    nativeCurrency: {
        name: 'Meverse',
        decimals: 18,
        symbol: 'MEV',
    },
    blockExplorerUrls: 'https://testnet.meversescan.io',
    scanUrls: '',
}