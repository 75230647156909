<template>
  <v-app id="app">
    <div class="wrapper">
      <app-bar />
      <router-view />
      <app-footer />
    </div>
    <components v-for="item in components" :key="item.id" :is="item.component" />
  </v-app>
</template>

<script>
import { mapState } from 'vuex';
import AppBar from "@/components/common/AppBar"
import AppFooter from '@/components/common/AppFooter';

export default {
  name: 'App',
  data() {
    return {
      countryCode: ''
    }
  },
  components: {
    AppBar,
    AppFooter
  },
  computed: {
    walletAddress() {
      return this.$store.state.user.userInfo.walletAddress;
    },
    ...mapState({
      components: state => state.components.items,
    }),
    userRefInfo() {
      return this.$store.state.user.userRefInfo;
    },
  },
  watch: {
    walletAddress() {
      if (this.walletAddress) {
        this.init()
      }
    }
  },
  async mounted() {
    await this.$store.dispatch('checkUserInfo');
    this.init()
  },
  methods: {
    async init() {
      await this.$store.dispatch('checkChain');
      await this.$store.dispatch('getReferralInfo', this.walletAddress);
    }
  }
};
</script>