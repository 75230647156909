import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import '@/assets/sass/style.scss';
import VueClipboard from 'vue-clipboard2'

Vue.config.productionTip = false
VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)
Vue.mixin({
  methods: {
    nFormatter(num) {
     if (num >= 1000000000) {
        return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
     }
     if (num >= 1000000) {
        return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
     }
     if (num >= 1000) {
        return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
     }
     return num;
    },
    insert_comma: function (balance, decimal_places) {
      if (!balance || balance.toString().length == 0) {
        if (balance === 0) {
          return "0";
        }
        return "";
      }
      var ls = balance.toString().split(".");
      var list = [];
      for (var i = 0; i < ls[0].length; i++) {
        list.push(ls[0][i]);
        if ((ls[0].length - i - 1) % 3 == 0 && i != ls[0].length - 1) {
          list.push(",");
        }
      }
      if (ls.length == 1) {
        return list.join("");
      } else {
        if (!decimal_places || isNaN(decimal_places)) {
          return list.join("") + "." + ls[1];
        } else {
          var dp = parseInt(decimal_places);
          if (ls[1].length < dp) {
            return list.join("") + "." + ls[1];
          } else {
            return list.join("") + "." + ls[1].substr(0, dp);
          }
        }
      }
    },
    dateParse(date) {
      const d = new Date(date);
      const year = d.getFullYear() + "";
      return year.substr(2) + "." + ((d.getMonth() + 1) > 9 ? (d.getMonth() + 1).toString() : "0" +
      (d.getMonth() + 1)) + "." + (d.getDate() > 9 ? d.getDate().toString() : "0" + d.getDate().toString());
    },
    toNumberFormat(str, len, paddOption) {
      if (typeof str === "undefined") {
        return "0";
      }
      if (typeof len === "undefined") {
        len = 2
      }
      var h = (str).toLocaleString('fullwide', {useGrouping:false}).split(".")
      h[0] = h[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      if (h.length == 2) {
        if (h[1].length > len) {
          h[1] = h[1].substr(0, len)
        }
        if (paddOption == "padding-zero") {
          var strs = h[1].split("")
          for ( var i = strs.length ; i < len ; i++ ) {
            strs.push("0");
          }
          h[1] = strs.join("")
        }
        str = h.join(".")
      } else {
        str = h[0]
      }
      return str
    },
    concatAddress(address, h, f) {
      const dots = '...';
      let head = address.slice(0, h);
      let foot = address.slice(address.length-f, address.length)
      return head.concat(dots).concat(foot);
    },
    providerType(type) {
      switch (type) {
        case 'Social Casual' : return 1;
        case 'Hyper Casual' : return 2;
        default : return 1;
      }
    },
    setCookie(cname, cvalue) {
      document.cookie = `${cname}=${cvalue};path=/`;
    },
    getCookie(cname) {
      const name = cname + '=';
      const decodedCookie = decodeURIComponent(document.cookie);
      const ca = decodedCookie.split(';');

      for (let i = 0; i < ca.length; i++) {
          let c = ca[i];

          while (c.charAt(0) === ' ') {
              c = c.substring(1);
          }

          if (c.indexOf(name) === 0) {
              return c.substring(name.length, c.length);
          }
      }

      return '';
    }
  }
})
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
